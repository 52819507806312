.about {
  width: 90%; /* Use more available space on smaller screens */
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.heading {
  font-size: 4vw; /* Responsive font size */
  font-weight: 600;
  margin-bottom: 1rem;
}

._img {
  width: 100%; /* Fully responsive width */
  max-width: 35em;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}

._content_wrapper {
  flex: 1;
  padding: 1rem;
  text-align: left;
}

.contact-btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 1.5rem;
  text-decoration: none;
  background-color: #464646;
  color: #fff;
  border-radius: 4px;
  font-size: 2.5vw; /* Responsive font size */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-btn:hover {
  background-color: #bf1000;
  transform: translateY(-2px);
}

.contact-btn i {
  margin-right: 8px;
}

.contact-btn:hover i {
  transform: none;
}

.contact-btn:active {
  transform: translateY(0);
}

.section-divider {
  border: none;
  border-top: 2px solid #bf1000;
  margin: 2rem 0;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

/* Medium screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .about {
    flex-direction: column;
    padding: 2rem;
  }

  ._img {
    max-width: 90%;
  }

  ._content_wrapper {
    width: 100%;
    text-align: center;
  }

  .heading {
    font-size: 5vw;
  }

  .contact-btn {
    font-size: 3vw;
  }
}

/* Small screens (max-width: 768px) */
@media (max-width: 768px) {
  .about {
    padding: 1rem;
  }

  ._img {
    margin-bottom: 1rem;
  }

  ._content_wrapper {
    width: 100%;
  }

  .heading {
    font-size: 6vw;
  }

  .contact-btn {
    font-size: 4vw;
  }
}

/* Extra small screens (max-width: 500px) */
@media (max-width: 500px) {
  .about {
    padding: 0.5rem; /* Reduced padding */
  }

  .heading {
    font-size: 7vw; /* Larger font size for better readability */
    margin-bottom: 0.75rem;
  }

  ._img {
    max-width: 80%; /* Smaller image width */
    margin-bottom: 1rem;
  }

  ._content_wrapper {
    padding: 0.5rem; /* Less padding on small screens */
    text-align: center; /* Center content for smaller screens */
  }

  .contact-btn {
    font-size: 5vw; /* Adjust button font size */
    padding: 8px 16px; /* Reduced padding for button */
  }
}
